import React, { memo, forwardRef } from "react";
import "./styles.css";

const Switch = memo(
  forwardRef(({ form, ...props }, ref) => {
    return (
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          ref={ref}
          {...props}
        />
        <label className="form-check-label" htmlFor={props.id}>
          {props.label}
        </label>
      </div>
    );
  })
);

export default Switch;
