const dataFields = {
  cardHolderName: {
    name: "card_holder_name",
    placeholder: "Digite o nome do titular",
    header: "Nome do Titular",
  },
  cardNumber: {
    name: "card_number",
    placeholder: "Digite o número do cartão",
    header: "Número do Cartão",
    maxLength: 19,
  },
  expirationDate: {
    name: "expiration_date",
    placeholder: "MM/AA",
    header: "Data de Validade",
    maxLength: 5,
  },
  securityCode: {
    name: "security_code",
    type: "text",
    placeholder: "CVV",
    header: "Cód. Segurança",
    maxLength: 3,
  },
};

export default dataFields;
