import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProcessingScreen from "../ProcessingScreen";
import TransactionSuccess from "../TransactionSuccess";
import * as Snowplow from "../../shared/services/snowplow";
import TransformValueByFieldName from "../../shared/transform/transformValueByFieldName";
import LocalStorageService from "../../shared/services/local-storage";
import IClinicPay from "../../images/iclinicpay.svg";
import Divider from "../../images/divider.svg";
import "./style.css";
import PageNotFound from "../../shared/components/PageNotFound";
import { ConsultationInfoCard, CardTips, CardFormSteps } from "./components";
import { useDispatch, useSelector } from "react-redux";
import InvoiceService from "../../service";
import { setInvoice } from "../../redux/slices/invoiceSlice";

function PaymentScreen() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, success, transactionId } = useSelector((state) => state.form);
  const invoice = useSelector((state) => state.invoice?.data);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchInvoice(id) {
      try {
        const response = await InvoiceService.fetchInvoice(id);
        dispatch(setInvoice({ data: { ...response.data }, error: false }));
      } catch (error) {
        dispatch(setInvoice({ data: {}, error: true }));
      } finally {
        setIsLoading(false);
      }
    }
    LocalStorageService.remove("accessToken");
    LocalStorageService.remove("refreshToken");
    LocalStorageService.remove("user");
    fetchInvoice(id);
  }, [id, dispatch]);

  Snowplow.formTracking({
    fields: {
      denylist: ["cvc"],
      transform: function (value, ele) {
        return TransformValueByFieldName(value, ele);
      },
    },
  });

  if (isLoading) {
    return <ProcessingScreen />;
  }

  const pageNotFound = invoice && Object.keys(invoice).length === 0;

  if (success) return <TransactionSuccess transactionId={transactionId} />;

  return (
    <>
      <div>
        <div className="container-pay">
          <div className="row container-pay-row">
            <div className="center">
              <div id="header">
                <div id="header-content">
                  <img src={IClinicPay} alt="" id="logo" />
                  <img src={Divider} alt="" id="divider" />
                  <img alt="" id="link" />
                </div>
              </div>
              {pageNotFound ? (
                <PageNotFound />
              ) : (
                <>
                  <div className="consultation-info-header">
                    <p className="consultation-info-header-text">
                      Cobrança de {invoice.sellerName}
                    </p>
                  </div>
                  <div className="row form-container">
                    <div className="consultation-info item-1">
                      <ConsultationInfoCard
                        favored={invoice.sellerName}
                        consultation={invoice.description}
                        amount={invoice.value}
                      />
                    </div>
                    <div className="card-form item-2">
                      <div className="card-form-content">
                        <CardFormSteps amount={invoice.value} />
                      </div>
                    </div>
                    <div className="item-3">
                      <CardTips />
                    </div>
                    {loading && <ProcessingScreen />}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentScreen;
