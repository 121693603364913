import React from "react";

const colors = new Map([
  ["default", "#000000"],
  ["primary", "#0063CF"],
  ["secondary", "#228BF0"],
  ["white", "#fff"],
  ["disabled", "#9A9A9A"],
  ["error", "#DA3333"],
  ["input-icon", "#607D8B"],
]);

/**
 * @typedef IconProps
 * @property {'primary'|'secondary'|'default'|'white'|'disabled'|'input-icon'} color
 * @property {'card'|'profile'|'check'|'arrow-right'|'pix'|'padlock'|'help'|'paper-copy'} name
 *
 * @param {IconProps} props
 */
const Icon = ({ color = "default", name, size, ...props }) => {
  switch (name) {
    case "card":
      return (
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill={colors.get(color)}
          {...props}
        >
          <path
            d="M12.6565 0.75H1.3435C1.05349 0.75 0.77535 0.865208 0.570279 1.07028C0.365208 1.27535 0.25 1.55349 0.25 1.8435V9.9795C0.25 10.1231 0.278284 10.2653 0.333238 10.398C0.388191 10.5306 0.468738 10.6512 0.570279 10.7527C0.67182 10.8543 0.792366 10.9348 0.925036 10.9898C1.05771 11.0447 1.1999 11.073 1.3435 11.073H12.6565C12.8001 11.073 12.9423 11.0447 13.075 10.9898C13.2076 10.9348 13.3282 10.8543 13.4297 10.7527C13.5313 10.6512 13.6118 10.5306 13.6668 10.398C13.7217 10.2653 13.75 10.1231 13.75 9.9795V1.8435C13.75 1.6999 13.7217 1.55771 13.6668 1.42504C13.6118 1.29237 13.5313 1.17182 13.4297 1.07028C13.3282 0.968738 13.2076 0.888191 13.075 0.833238C12.9423 0.778284 12.8001 0.75 12.6565 0.75ZM2.0185 2.1015H11.9815C12.092 2.1019 12.1978 2.14596 12.2759 2.22407C12.354 2.30219 12.3981 2.40803 12.3985 2.5185V3.633H1.5985V2.5185C1.5987 2.46354 1.60972 2.40916 1.63093 2.35846C1.65214 2.30776 1.68313 2.26174 1.72214 2.22301C1.76114 2.18429 1.80738 2.15363 1.85823 2.13278C1.90908 2.11193 1.96354 2.1013 2.0185 2.1015ZM11.9815 9.7215H2.0185C1.90803 9.72111 1.80219 9.67705 1.72407 9.59893C1.64596 9.52081 1.6019 9.41498 1.6015 9.3045V5.6865H12.4015V9.3045C12.4013 9.35946 12.3903 9.41384 12.3691 9.46454C12.3479 9.51524 12.3169 9.56126 12.2779 9.59999C12.2389 9.63871 12.1926 9.66937 12.1418 9.69022C12.0909 9.71107 12.0365 9.7217 11.9815 9.7215Z"
            fill={colors.get(color)}
          />
        </svg>
      );

    case "profile":
      return (
        <svg
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill={colors.get(color)}
          {...props}
        >
          <path
            d="M7.00032 0C5.34359 0 4.00011 1.34285 4.00011 3.00021C4.00011 4.65757 5.34359 6.00042 7.00032 6.00042C8.65705 6.00042 10.0005 4.65694 10.0005 3.00021C10.0005 1.34348 8.65768 0 7.00032 0ZM7.00032 7.40592C4.74747 7.40592 0.25 8.53677 0.25 10.7808V12.6558H13.75V10.7808C13.75 8.53677 9.25316 7.40592 6.99968 7.40592H7.00032Z"
            fill={colors.get(color)}
          />
        </svg>
      );

    case "check":
      return (
        <svg
          width="17"
          height="13"
          viewBox="0 0 17 13"
          fill={colors.get(color)}
          {...props}
        >
          <path
            d="M5.57825 9.642L15.2202 0L16.5005 1.28025L5.57825 12.2025L0.5 7.12575L1.78025 5.8455L5.57825 9.642Z"
            fill={colors.get(color)}
          />
        </svg>
      );

    case "arrow-right":
      return (
        <svg
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill={colors.get(color)}
          {...props}
        >
          <path
            d="M13 14L11.575 12.6L16.175 8H0V6H16.175L11.6 1.4L13 0L20 7L13 14Z"
            fill={colors.get(color)}
          />
        </svg>
      );

    case "arrow-left":
      return (
        <svg width="17" height="13" viewBox="0 0 17 13" fill={colors.get(color)}>
          <path
            d="M1.01481 7.45463L6.1787 12.6185C6.52778 12.9676 7.10556 12.9676 7.45463 12.6185C7.8037 12.2694 7.8037 11.6917 7.45463 11.3426L3.83148 7.71945H16.0972C16.5907 7.71945 17 7.31019 17 6.81667C17 6.32315 16.5907 5.91389 16.0972 5.91389H3.83148L7.45463 2.29074C7.8037 1.94167 7.8037 1.36389 7.45463 1.01482C7.27407 0.834261 7.04537 0.75 6.81667 0.75C6.58796 0.75 6.35926 0.834261 6.1787 1.01482L1.01481 6.17871C0.846295 6.34722 0.75 6.57593 0.75 6.81667C0.75 7.05741 0.846295 7.28611 1.01481 7.45463Z"
            fill={colors.get(color)}
          />
        </svg>
      );

    case "pix":
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill={colors.get(color)}
          {...props}
        >
          <path
            d="M17.5973 17.299C17.2116 17.3004 16.8295 17.2252 16.4729 17.0781C16.1164 16.9309 15.7926 16.7147 15.5202 16.4417L12.5198 13.4394C12.4137 13.3379 12.2725 13.2812 12.1257 13.2812C11.9789 13.2812 11.8377 13.3379 11.7316 13.4394L8.72115 16.4499C8.4489 16.7233 8.12516 16.9401 7.76864 17.0877C7.41213 17.2353 7.0299 17.3107 6.64404 17.3097H6.05664L9.85855 21.1117C10.4287 21.6807 11.2014 22.0002 12.0069 22.0002C12.8124 22.0002 13.5851 21.6807 14.1553 21.1117L17.9648 17.3015L17.5973 17.299Z"
            fill={colors.get(color)}
          />
          <path
            d="M6.64467 6.68886C7.03053 6.68783 7.41277 6.7633 7.7693 6.91089C8.12582 7.05848 8.44955 7.27527 8.72178 7.54873L11.7322 10.5598C11.8369 10.6642 11.9786 10.7227 12.1263 10.7227C12.2741 10.7227 12.4158 10.6642 12.5205 10.5598L15.5202 7.56013C15.7922 7.28633 16.1159 7.06927 16.4724 6.92156C16.829 6.77384 17.2113 6.69841 17.5973 6.69964H17.9585L14.149 2.89013C13.8669 2.60793 13.5319 2.38407 13.1633 2.23134C12.7947 2.07861 12.3996 2 12.0006 2C11.6016 2 11.2064 2.07861 10.8378 2.23134C10.4692 2.38407 10.1343 2.60793 9.85221 2.89013L6.05664 6.68886H6.64467Z"
            fill={colors.get(color)}
          />
          <path
            d="M21.1098 9.85125L18.8078 7.54919C18.7558 7.57042 18.7004 7.5816 18.6443 7.58214H17.5975C17.0528 7.58351 16.5306 7.79984 16.1445 8.18411L13.1448 11.182C12.8747 11.4516 12.5086 11.6031 12.1269 11.6031C11.7452 11.6031 11.3791 11.4516 11.1089 11.182L8.0978 8.17271C7.71182 7.7882 7.18965 7.57164 6.64484 7.57011H5.35979C5.30683 7.56891 5.2545 7.5584 5.20518 7.53906L2.88856 9.85125C2.31956 10.4215 2 11.1942 2 11.9997C2 12.8052 2.31956 13.5779 2.88856 14.1481L5.20011 16.4596C5.24932 16.4399 5.30172 16.4294 5.35472 16.4286H6.64484C7.18963 16.427 7.71178 16.2104 8.0978 15.826L11.1083 12.8155C11.6526 12.2718 12.6012 12.2718 13.1448 12.8155L16.1445 15.8146C16.5306 16.1989 17.0528 16.4152 17.5975 16.4166H18.6443C18.7004 16.4169 18.7559 16.4281 18.8078 16.4495L21.1098 14.1475C21.392 13.8654 21.6159 13.5304 21.7686 13.1618C21.9213 12.7932 21.9999 12.3981 21.9999 11.9991C21.9999 11.6 21.9213 11.2049 21.7686 10.8363C21.6159 10.4677 21.392 10.1334 21.1098 9.85125Z"
            fill={colors.get(color)}
          />
        </svg>
      );

    case "padlock":
      return (
        <svg
          width="16"
          height="21"
          viewBox="0 0 16 21"
          fill={colors.get(color)}
          {...props}
        >
          <path
            d="M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM8 16C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16ZM11.1 7H4.9V5C4.9 3.29 6.29 1.9 8 1.9C9.71 1.9 11.1 3.29 11.1 5V7Z"
            fill={colors.get(color)}
          />
        </svg>
      );

    case "help":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill={colors.get(color)}
          {...props}
        >
          <path
            d="M9.95 16C10.3 16 10.5958 15.8792 10.8375 15.6375C11.0792 15.3958 11.2 15.1 11.2 14.75C11.2 14.4 11.0792 14.1042 10.8375 13.8625C10.5958 13.6208 10.3 13.5 9.95 13.5C9.6 13.5 9.30417 13.6208 9.0625 13.8625C8.82083 14.1042 8.7 14.4 8.7 14.75C8.7 15.1 8.82083 15.3958 9.0625 15.6375C9.30417 15.8792 9.6 16 9.95 16ZM9.05 12.15H10.9C10.9 11.6 10.9625 11.1667 11.0875 10.85C11.2125 10.5333 11.5667 10.1 12.15 9.55C12.5833 9.11667 12.925 8.70417 13.175 8.3125C13.425 7.92083 13.55 7.45 13.55 6.9C13.55 5.96667 13.2083 5.25 12.525 4.75C11.8417 4.25 11.0333 4 10.1 4C9.15 4 8.37917 4.25 7.7875 4.75C7.19583 5.25 6.78333 5.85 6.55 6.55L8.2 7.2C8.28333 6.9 8.47083 6.575 8.7625 6.225C9.05417 5.875 9.5 5.7 10.1 5.7C10.6333 5.7 11.0333 5.84583 11.3 6.1375C11.5667 6.42917 11.7 6.75 11.7 7.1C11.7 7.43333 11.6 7.74583 11.4 8.0375C11.2 8.32917 10.95 8.6 10.65 8.85C9.91667 9.5 9.46667 9.99167 9.3 10.325C9.13333 10.6583 9.05 11.2667 9.05 12.15ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z"
            fill="#607D8B"
          />
        </svg>
      );

    case "paper-copy":
      return (
        <svg
          width="17"
          height="20"
          viewBox="0 0 17 20"
          fill={colors.get(color)}
          {...props}
        >
          <path
            d="M14.8337 15.832H5.66699C5.20866 15.832 4.8163 15.6688 4.48991 15.3424C4.16352 15.0161 4.00033 14.6237 4.00033 14.1654V2.4987C4.00033 2.04036 4.16352 1.648 4.48991 1.32161C4.8163 0.995226 5.20866 0.832031 5.66699 0.832031H11.5003L16.5003 5.83203V14.1654C16.5003 14.6237 16.3371 15.0161 16.0107 15.3424C15.6844 15.6688 15.292 15.832 14.8337 15.832ZM10.667 6.66536V2.4987H5.66699V14.1654H14.8337V6.66536H10.667ZM2.33366 19.1654C1.87533 19.1654 1.48296 19.0022 1.15658 18.6758C0.830187 18.3494 0.666992 17.957 0.666992 17.4987V5.83203H2.33366V17.4987H11.5003V19.1654H2.33366Z"
            fill={colors.get(color)}
          />
        </svg>
      );

    default: {
      throw new Error(`Unknown icon name: ${name}`);
    }
  }
};

export default Icon;
