import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import LocalStorageService from "./shared/services/local-storage";
import { environment } from "./environments/environment";
import Routes from "./Routes";
import * as Snowplow from "../src/shared/services/snowplow";

function App() {
  Snowplow.init();
  LocalStorageService.setObject("environment", environment.name);

  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
