import { configureStore } from "@reduxjs/toolkit";
import { formSlice, invoiceSlice, personalDataSlice } from "./slices";

const store = configureStore({
  reducer: {
    personalData: personalDataSlice,
    form: formSlice,
    invoice: invoiceSlice,
  },
});

export default store;
