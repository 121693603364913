import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isValid: false,
  step: 1,
  paymentMethod: "",
  qrCode: "",
  loading: false,
  success: false,
  transactionId: "",
  loadingGetForm: false,
};

const formSlice = createSlice({
  name: "form",
  initialState: initialState,
  reducers: {
    setIsValidForm: (state, action) => {
      state.isValid = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setFormPendingLoading: (state, action) => {
      state.loadingGetForm = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setTransactionId: (state, action) => {
      state.transactionId = action.payload;
    },
    setQrCodeState: (state, action) => {
      state.qrCode = action.payload;
    },
  },
});

export const {
  setIsValidForm,
  setStep,
  setLoading,
  setFormPendingLoading,
  setSuccess,
  setPaymentMethod,
  setTransactionId,
  setQrCodeState,
} = formSlice.actions;

export default formSlice.reducer;
