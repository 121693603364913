const dataFields = {
  full_name: {
    name: "full_name",
    placeholder: "Digite seu nome",
    header: "Nome Completo",
  },
  cpf: {
    name: "cpf",
    placeholder: "Digite o CPF",
    header: "CPF",
    maxLength: 14,
  },
  email: {
    name: "email",
    type: "email",
    placeholder: "Digite seu email",
    header: "Email",
  },
  phone: {
    name: "phone",
    type: "number",
    placeholder: "Digite seu telefone",
    header: "Telefone",
  },
  postalCode: {
    name: "postal_code",
    type: "number",
    placeholder: "Digite seu CEP",
    header: "CEP",
  },
  street: {
    name: "street",
    placeholder: "Digite sua rua",
    header: "Rua",
  },
  number: {
    name: "number",
    placeholder: "Digite o número",
    header: "Número",
  },
  complement: {
    name: "complement",
    placeholder: "Digite o complemento",
    header: "Complemento",
  },
  neighborhood: {
    name: "neighborhood",
    placeholder: "Digite o bairro",
    header: "Bairro",
  },
  state: {
    name: "state",
    placeholder: "Digite o estado",
    header: "Estado",
  },
  city: {
    name: "city",
    placeholder: "Digite a cidade",
    header: "Cidade",
  },
};

export default dataFields;
