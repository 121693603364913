import React from "react";
import "./style.css";
import { useSelector } from "react-redux";

export default function ProcessingScreen() {
  const { paymentMethod, step } = useSelector((state) => state.form);

  const isPixLoading = paymentMethod === "pix" && step === 2;

  return (
    <div className={`container-processing ${isPixLoading && "pix-loading"}`}>
      <div className="card">
        <div className="spinner-border text-primary" role="status" />
        <p className="processing-text">Processando pagamento</p>
      </div>
    </div>
  );
}
