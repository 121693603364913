import React from "react";
import { Controller } from "react-hook-form";
import { Input } from "../../shared/components/UI";
import Icon from "../../components/V2/Icon/Icon";

class InputService {
  constructor(control, errors) {
    this.control = control;
    this.errors = errors || {};
  }

  renderInput({
    name,
    type = "text",
    placeholder,
    header,
    internalOnChange = null,
    ...rest
  }) {
    return (
      <div className={`input-container ${name}-input`}>
        <Controller
          name={name}
          control={this.control}
          render={({ field: { onChange, ...field } }) => (
            <Input
              {...field}
              {...rest}
              onChange={(e) => {
                onChange(e);
                if (internalOnChange) {
                  internalOnChange(e);
                }
              }}
              role="presentation"
              placeholder={placeholder}
              header={header}
              type={type}
              errors={{ [name]: this.errors?.[name]?.message || "" }}
            />
          )}
        />
        <div className="icon-container">
          {name === "card_number" || name === "security_code" ? (
            <Icon
              name={name === "card_number" ? "card" : "padlock"}
              color="input-icon"
              width={20}
              height={name === "card_number" ? 16 : 20}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default InputService;
