import React from "react";
import { formatCurrency } from "./formatter";

const renderInstallments = (installments) => {
  return installments.map((item) => (
    <option value={item.installments} key={item.installments}>
      {item.installments === 1
        ? `À vista, ${formatCurrency(item.totalValue)}`
        : `${item.installments}x de ${formatCurrency(item.installmentValue)} ${
            !item.interestFree ? `(${formatCurrency(item.totalValue)})` : ""
          }`}
    </option>
  ));
};

export default renderInstallments;
