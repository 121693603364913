const requiredFields = [
  "full_name",
  "cpf",
  "email",
  "phone",
  "postal_code",
  "number",
  "street",
  "neighborhood",
  "city",
  "state",
];

export default requiredFields;
