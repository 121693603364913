import React, { forwardRef, memo, useEffect, useState } from "react";
import "./styles.css";

const Select = memo(
  forwardRef(({ form, ...props }, ref) => {
    const [showMsg, setShowMsg] = useState(false);
    const [focusedElement, setFocusedElement] = useState("");

    useEffect(() => {
      const existError =
        props.errors && Object.keys(props.errors).length > 0 && props.errors[props.name];
      setShowMsg(existError);
    }, [props.errors, props.name]);

    const focusField = () => {
      setFocusedElement(document.activeElement.id);
    };

    const blurField = () => {
      setFocusedElement("");
    };

    return (
      <span className="field">
        <div
          className={`box-field ${showMsg ? "input-error" : ""} ${
            focusedElement === props.id ? "input-valid" : ""
          }`}
        >
          <span
            className={`header ${showMsg ? "header-invalid" : ""} ${
              (props.value !== "" || focusedElement === props.id) && props.value !== ""
                ? "header-valid"
                : ""
            }`}
          >
            {props.header}
          </span>
          <select
            {...props}
            ref={ref}
            className="form-select"
            onFocus={focusField}
            onBlur={blurField}
          >
            {props.options}
            {props.novo === "true" && (
              <option value={"new"} key={"new"}>
                Novo
              </option>
            )}
          </select>
        </div>
        {showMsg && <div className="msg-error">{props.errors[props.name]}</div>}
      </span>
    );
  })
);

export default Select;
