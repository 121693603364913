import React, { useState, useEffect, useRef } from "react";
import useForm from "../../shared/hooks/useForm";
import validate from "../../shared/validations/paymentLoggedInFormValidationRules";
import { formatCurrency } from "../../utils/formatter";
import InvoiceService from "../../service";
import * as Sentry from "@sentry/react";
import TransactionSuccess from "../TransactionSuccess";
import TransactionFail from "../TransactionFail";
import LocalStorageService from "../../shared/services/local-storage";
import { useHistory } from "react-router-dom";
import { Input, Switch, Select } from "../../shared/components/UI";
import formatValue from "../../shared/formatation/formatationRules";
import "./style.css";
import * as Snowplow from "../../shared/services/snowplow";
import TransformValueByFieldName from "../../shared/transform/transformValueByFieldName";
import InfoUser from "../../shared/components/InfoUser";
import Container from "../../shared/components/Container";
import { Toast } from "../../shared/components/UI/Toast";
import ReCAPTCHA from "react-google-recaptcha";

import {
  Bold,
  ButtonError,
  Cancel,
  ModalTitle,
  RemoveSelectedCard,
  Text,
  Value,
} from "./style";
import Modal from "react-modal";
import BasicLoadingScreen from "../BasicLoadingScreen";
import ProcessingScreen from "../ProcessingScreen";
import { ButtonPrimary } from "../../shared/components/UI/ButtonPrimary";
import PaymentPix from "../../shared/components/PaymentPix";
import { environment } from "../../environments/environment";
import renderInstallments from "../../utils/renderInstallments";
import { useSelector } from "react-redux";

const _paq = window._paq || [];

const customStyles = {
  overlay: {
    backgroundColor: "rgba(230, 230, 230, 0.75)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "24px",
    position: "absolute",
    width: "400px",
    height: "180px",
    background: "#FFFFFF",
    boxShadow:
      "0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    border: "0",
  },
};

Modal.setAppElement("#root");

const validateEmptyValues = (values) => {
  return (
    values["ccname"] === "" ||
    values["cardnumber"] === "" ||
    values["ccexp"] === "" ||
    values["cvc"] === ""
  );
};

function PaymentLoggedIn() {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [token, setToken] = useState("");
  const invoice = useSelector((state) => state.invoice?.data);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  Snowplow.formTracking({
    fields: {
      denylist: ["cvc"],
      transform: function (value, ele) {
        return TransformValueByFieldName(value, ele);
      },
    },
  });

  const initialValues = {
    ccname: "",
    cardnumber: "",
    ccexp: "",
    cvc: "",
    installments: 1,
  };

  const [saveCard, setSaveCard] = useState(true);
  const [transactionResponse, setTransactionResponse] = useState({});
  const [basicLoading, setBasicLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [valuePaymentMethod, setValuePaymentMethod] = useState("");
  const [step, setStep] = useState("");

  const creditCards = LocalStorageService.getUser()?.cards || [];
  const { values, handleChange, onSubmit, patchValue, errors } = useForm({
    handleSubmit,
    validate,
    initialValues,
    formatValue,
  });
  let history = useHistory();

  useEffect(() => {
    if (!LocalStorageService.checkAuthorize()) {
      history.push({ pathname: "/login" });
    }
  }, [history]);

  useEffect(() => {
    if (creditCards.length && !values.credit_card_id) {
      patchValue("credit_card_id", creditCards[0].id);
    }
    if (!creditCards.length && !values.credit_card_id) {
      patchValue("credit_card_id", "new");
    }
  }, [creditCards, patchValue, values]);

  async function handleSubmit(event) {
    let formData;
    const { totalValue } = invoice.installmentOptions.find(
      (option) => option.installments === Number(values.installments)
    );

    if (creditCards.length && values.credit_card_id !== "new") {
      formData = {
        card_id: values.credit_card_id,
        installments: Number(values.installments) || 1,
        value: String(totalValue),
        token: "token_dev",
      };
    } else {
      formData = {
        card_number: values.cardnumber.replace(/\D/g, ""),
        card_holder_name: values.ccname,
        cpf: LocalStorageService.getObject("user").cpf.replace(/\D/g, ""),
        expiration_month: Number(values.ccexp.split("/")[0]),
        expiration_year: Number(`20${values.ccexp.split("/")[1]}`),
        security_code: values.cvc.replace(/\D/g, ""),
        installments: Number(values.installments) || 1,
        value: String(totalValue),
        token: "token_dev",
      };
    }

    if (
      environment.name !== "dev" &&
      environment.captcha_card_number.indexOf(formData.card_number) === -1
    ) {
      formData["token"] = captchaRef.current.getValue();
    }

    if (Object.values(formData).some((field) => !field)) return;

    _paq.push(["trackEvent", "Pagamento iniciado", `Link ${invoice.id}`, totalValue]);

    setProcessing(true);

    let result = {};
    let transactionId = "";
    try {
      if (saveCard === false) {
        result = await InvoiceService.handlePaymentWithoutSavingCard(
          invoice.id,
          formData
        );
      } else {
        result =
          creditCards.length && values.credit_card_id !== "new"
            ? await InvoiceService.handlePaymentWithExistingCard(invoice.id, formData)
            : await InvoiceService.handlePaymentSavingCard(invoice.id, formData);

        if (result.user) {
          LocalStorageService.setObject("user", result.user);
        }
      }

      transactionId = result.data.id;
      setTransactionResponse({ type: "success", transactionId });

      _paq.push([
        "trackEvent",
        "Pagamento concluído com sucessso",
        `Link ${invoice.id}`,
        totalValue,
      ]);
    } catch (e) {
      setTransactionResponse({ type: "fail" });
      console.error("Error trying to proccess transaction", e);
      Sentry.captureException(e);
      if (e?.response?.data?.user) {
        LocalStorageService.setObject("user", e?.response?.data?.user);
      }
      _paq.push([
        "trackEvent",
        "Pagamento concluído com erro",
        `Link ${invoice.id}`,
        (e && e.response && e.response.data && e.response.data.error) ||
          "Erro não identificado",
        totalValue,
      ]);
    } finally {
      setProcessing(false);
    }
  }

  const handlePaymentFieldsChange = (event) => {
    patchValue([event.target.name], event.target.value);
  };

  const renderCreditCards = (creditCards) => {
    return creditCards.map((item, index) => (
      <option value={item.id} key={item.id}>
        {`${item.cardBrand} terminado em ${item.last4Digits}`}
      </option>
    ));
  };

  const renderPaymentMethods = () => {
    return (
      <>
        <option value="" key={"empty"}>
          Selecione uma forma de pagamento
        </option>
        <option value={"card"} key="card">
          Cartão de crédito
        </option>
        <option value={"pix"} key="pix">
          PIX
        </option>
      </>
    );
  };

  const deleteSelectedCard = async () => {
    setTimeout(async () => {
      try {
        setBasicLoading(true);
        const result = await InvoiceService.removeSavedCard(values.credit_card_id);

        values.credit_card_id = "new";

        if (result?.data?.user) {
          LocalStorageService.setObject("user", result?.data?.user);

          if (result?.data?.user && result?.data?.user.cards.length > 0) {
            values.credit_card_id = result?.data?.user.cards[0].id;
          }
        }
        setSuccessMsg("Cartão excluído com sucesso!");
      } catch (e) {
        if (e?.response?.data?.user) {
          LocalStorageService.setObject("user", e?.response?.data?.user);
        }
        console.error("error deleting selected card", e);
        setErrorMsg("Erro ao excluir cartão!");
      } finally {
        closeModal();
        setBasicLoading(false);
      }
    }, 0);
  };

  const handlePaymentMethodsChange = (event) => {
    setValuePaymentMethod(event.target.value);
  };

  const tokenOrCardBypass = () => {
    const cardNumber = values.cardnumber.replace(/\D/g, "");

    if (
      environment.name === "dev" ||
      environment.captcha_card_number.indexOf(cardNumber) > -1
    ) {
      return false;
    }
    return token === "";
  };

  const handleNextStep = (value) => {
    setStep(value);
  };

  const handleGoBack = () => {
    setStep("");
  };
  const captchaRef = useRef(null);

  if (basicLoading) return <BasicLoadingScreen />;
  if (processing) return <ProcessingScreen />;
  if (transactionResponse.type === "success")
    return <TransactionSuccess transactionId={transactionResponse.transactionId} />;
  if (transactionResponse.type === "fail")
    return <TransactionFail onBackPress={() => setTransactionResponse({})} />;

  const isAuthenticated = !!window.localStorage.getItem("user");
  const cardRegister = !creditCards.length;

  return (
    <>
      <Toast error msg={errorMsg}></Toast>
      <Toast success msg={successMsg}></Toast>
      {isAuthenticated ? <InfoUser></InfoUser> : <></>}
      <Container>
        <Text>
          <p>
            {cardRegister
              ? `Cadastre um novo cartão para pagar a cobrança enviada por ${" "}`
              : `Selecione um cartão ou cadastre um novo para pagar a cobrança enviada por${" "}`}
            <Bold>{invoice.sellerName}</Bold>, no valor de{" "}
            <Value>{formatCurrency(invoice.value)}</Value>, referente a{" "}
            {invoice.description}.
          </p>
        </Text>

        {step === "" && (
          <>
            <Select
              header="Forma de pagamento"
              name="paymentMethod"
              id="paymentMethod"
              onChange={handlePaymentMethodsChange}
              options={renderPaymentMethods()}
              value={valuePaymentMethod}
            />
            <div className="mt-24">
              <ButtonPrimary
                text="Avançar"
                className="btn-next"
                onClick={() => handleNextStep(valuePaymentMethod)}
                disabled={valuePaymentMethod === ""}
              />
            </div>
          </>
        )}

        {step === "card" && (
          <>
            <form onSubmit={(event) => onSubmit(event)}>
              <Select
                header="Cartão para pagamento"
                name="credit_card_id"
                id="credit_card_id"
                value={values["credit_card_id"] || ""}
                onChange={handlePaymentFieldsChange}
                options={renderCreditCards(creditCards)}
                novo="true"
              />
              {!creditCards.length || values.credit_card_id === "new" ? (
                <>
                  <Input
                    name="ccname"
                    value={values["ccname"] || ""}
                    onChange={handleChange}
                    type="text"
                    id="ccname"
                    autoComplete="cc-name"
                    placeholder="Digite o nome"
                    maxLength={26}
                    errors={errors}
                    header="Nome do titular do cartão"
                  />
                  <Input
                    name="cardnumber"
                    value={values["cardnumber"] || ""}
                    onChange={handleChange}
                    type="text"
                    id="cardnumber"
                    autoComplete="cc-number"
                    placeholder="Digite o número do cartão"
                    maxLength={19}
                    errors={errors}
                    header="Número do cartão"
                  />
                  <div className="row">
                    <div className="col-sm-6">
                      <Input
                        name="ccexp"
                        value={values["ccexp"] || ""}
                        onChange={handleChange}
                        id="ccexp"
                        autoComplete="cc-exp"
                        type="text"
                        placeholder="MM/AA"
                        maxLength={5}
                        errors={errors}
                        header="Vencimento do cartão"
                      />
                    </div>
                    <div className="col-sm-6">
                      <Input
                        name="cvc"
                        value={values["cvc"] || ""}
                        onChange={handleChange}
                        id="cvc"
                        autoComplete="cc-csc"
                        type="text"
                        placeholder="CVV"
                        maxLength={4}
                        errors={errors}
                        header="Código de segurança"
                      />
                    </div>
                  </div>
                </>
              ) : null}
              <Select
                header="Número de parcelas"
                name="installments"
                id="installments"
                value={values["installments"] || ""}
                onChange={handleChange}
                options={renderInstallments(invoice.installmentOptions)}
                novo="false"
              />
              {environment.name !== "dev" && (
                <>
                  <div style={{ marginTop: 8, marginBottom: 8 }}>
                    <ReCAPTCHA
                      sitekey={environment.captcha_site_key}
                      ref={captchaRef}
                      onChange={setToken}
                    />
                  </div>
                </>
              )}
              {values.credit_card_id !== "new" ? (
                <>
                  <RemoveSelectedCard onClick={() => openModal()}>
                    Remover cartão selecionado
                  </RemoveSelectedCard>

                  <div className="rowButtons">
                    <ButtonPrimary
                      onClick={handleGoBack}
                      text="Voltar"
                      icon="arrow_back"
                      link
                      type="button"
                      className="btn-previous"
                    />
                    <ButtonPrimary
                      disabled={tokenOrCardBypass()}
                      className="w-150"
                      text="Pagar"
                    />
                  </div>
                  <div className="smallButtons">
                    <ButtonPrimary disabled={tokenOrCardBypass()} text="Pagar" />
                    <ButtonPrimary
                      onClick={handleGoBack}
                      text="Voltar"
                      icon="arrow_back"
                      link
                      type="button"
                      className="btn-previous"
                    />
                  </div>
                </>
              ) : null}
              {values.credit_card_id === "new" ? (
                <>
                  <div className="row mt-24">
                    <div className="col-sm-9 m-0-auto">
                      <Switch
                        name="saveCard"
                        id="saveCard"
                        onChange={() => {
                          setSaveCard(!saveCard);
                        }}
                        defaultChecked={saveCard}
                        label="Salvar o cartão para uso futuro"
                      />
                    </div>
                  </div>
                  <div className="row mt-24">
                    <ButtonPrimary
                      disabled={tokenOrCardBypass() || validateEmptyValues(values)}
                      text="Pagar"
                      className="w-95-p"
                      full
                    />
                  </div>
                </>
              ) : null}
            </form>
          </>
        )}

        {step === "pix" && (
          <>
            <PaymentPix
              onTransactionResponse={setTransactionResponse}
              onPreviousPage={handleGoBack}
            />
          </>
        )}
      </Container>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ModalTitle>
          Tem certeza que deseja <b>excluir</b> esse cartão de da sua conta?
        </ModalTitle>
        <div className="row rowButtonsModal">
          <div className="col-sm-7 colAlignMiddle">
            <Cancel onClick={() => closeModal()}>Cancelar</Cancel>
          </div>
          <div className="col-sm-5">
            <ButtonError onClick={() => deleteSelectedCard()}>Sim, quero</ButtonError>
          </div>
        </div>
        <div className="smallButtonsModal">
          <div className="col-sm-7 colAlignMiddle">
            <Cancel onClick={() => closeModal()}>Cancelar</Cancel>
          </div>
          <div className="col-sm-5">
            <ButtonError onClick={() => deleteSelectedCard()}>Sim, quero</ButtonError>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PaymentLoggedIn;
