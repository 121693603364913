const date = {
  mask(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})/, "$1/$2");
  },
  unmask(value) {
    return value.replace(/\//g, "");
  },
};
export default date;
