const cpf = {
  mask(value) {
    return value
      ?.replace(/\D/g, "")
      ?.replace(/(\d{3})(\d)/, "$1.$2")
      ?.replace(/(\d{3})(\d)/, "$1.$2")
      ?.replace(/(\d{3})(\d{1,2})/, "$1-$2")
      ?.replace(/(-\d{2})\d+?$/, "$1");
  },
  unmask(value) {
    return value?.replace(/\./g, "")?.replace(/-/, "");
  },
};

export default cpf;
