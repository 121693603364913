const cardNumber = {
  mask(value) {
    return value
      ?.replace(/\D/g, "")
      ?.replace(/(\d{4})(?=\d)/g, "$1 ")
      ?.trim();
  },
  unmask(value) {
    return value?.replace(/\s+/g, "");
  },
};

export default cardNumber;
