import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 30px 0px 65px 0px;
`;

export const Title = styled.h3`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-top: 8px;
`;

export const SubTitle = styled.h4`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 4px;
  max-width: 300px;
  text-align: center;
`;

export const Button = styled.button`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  line-height: 16px;
  margin-top: 24px;
  width: 248px;
`;
