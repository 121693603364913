import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./StepTwo.styles";
import Icon from "../../../../../V2/Icon/Icon";
import CreditCardMethod from "./methods/CreditCardMethod/CreditCardMethod";
import PaymentPix from "../../../../../../shared/components/PaymentPix";
import {
  setIsValidForm,
  setPaymentMethod,
} from "../../../../../../redux/slices/formSlice";

const paymentMethods = [
  { id: "credit-card", label: "Cartão de Crédito", icon: "card" },
  { id: "pix", label: "Pix", icon: "pix" },
];

const StepTwo = ({ formRef }) => {
  const dispatch = useDispatch();
  const selectedPaymentMethod = useSelector((state) => state.form.paymentMethod);

  return (
    <S.StepTwoContainer>
      <div>
        <div className="title-container">
          <img src="images/icons/money-icon.png" alt="local-icon" />
          <p>Formas de Pagamento</p>
        </div>
        <div className="payment-methods">
          {paymentMethods.map((method) => (
            <div
              key={method.id}
              className={`${method.id} ${selectedPaymentMethod === method.id ? "active" : ""}`}
              onClick={() => {
                dispatch(setPaymentMethod(method.id));
                dispatch(setIsValidForm(false));
              }}
            >
              <Icon
                name={method.icon}
                color={selectedPaymentMethod === method.id ? "white" : "primary"}
                width={24}
                height={method.id === "pix" ? 24 : 20}
              />
              <p>{method.label}</p>
            </div>
          ))}
        </div>

        {selectedPaymentMethod === "credit-card" && (
          <CreditCardMethod formRef={formRef} />
        )}

        {selectedPaymentMethod === "pix" && <PaymentPix />}
      </div>
    </S.StepTwoContainer>
  );
};

export default StepTwo;
