import styled from "styled-components";

export const FixedStepControlsContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 128px;
  background-color: #ffffff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px 16px;

  .loading {
    background-color: #0063cf;
  }

  .btn-next-step {
    margin-top: 10px;
    width: 100%;
    height: 48px;
    font-size: 15px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
  }

  .total-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .total-text {
    font-size: 14px;
    font-weight: 600;
    color: #455a64;
  }

  .total-value {
    font-size: 20px;
    font-weight: 700;
    color: #0063cf;
  }

  .active-button {
    background-color: #0063cf;
    color: #ffffff;
  }

  .inactive-button {
    background-color: #eceff1;
    color: #90a4ae;
    pointer-events: none;
  }

  .btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .btn-back-step {
    display: none;
  }

  @media (min-width: 768px) {
    position: absolute;
    height: auto;

    .total-container {
      display: none;
    }

    .btn-next-step {
      margin: 5px 0;
    }

    > div {
      display: flex;
      gap: 10%;
      padding: 0 16px;
    }

    .btn-back-step {
      display: flex;
      background-color: #eceff1;
      color: #0063cf;
      background-color: white;

      svg {
        padding-bottom: 3px;
        width: 20px;
        height: 16px;
      }
    }
  }
`;
