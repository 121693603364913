/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import * as Sentry from "@sentry/react";
import * as S from "./styles";
import Icon from "../UI/Icon";
import ProcessingScreen from "../../../components/ProcessingScreen";
import Ellapsed from "../Ellapsed";
import InvoiceService from "../../../service";
import { copyTextOrShare } from "../../../utils/functions";
import { environment } from "../../../environments/environment";
import PixBadRequestScreen from "./PixBadRequestScreen";
import {
  EmailShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  WhatsappIcon,
  EmailIcon,
  TelegramIcon,
} from "react-share";
import { formatCurrency } from "../../../utils/formatter";
import preparePayload from "./utils/preparePayload";
import {
  setIsValidForm,
  setQrCodeState,
  setSuccess,
  setTransactionId,
} from "../../../redux/slices/formSlice";

function PaymentPix() {
  const dispatch = useDispatch();
  const buyer = useSelector((state) => state.personalData);
  const [expired, setExpired] = useState(false);
  const [expirationDate, setExpirationDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [qrCode, setQrCode] = useState("");
  const [fgColor, setFgColor] = useState("#0063CF");
  const [badRequest, setBadRequest] = useState(false);
  const invoice = useSelector((state) => state.invoice?.data);
  const title = `Chave PIX referente a pagamento de ${formatCurrency(invoice.value)} para ${invoice.sellerName} pelo iClinic Pay`;

  const handleBarCodeCopy = async () => {
    await copyTextOrShare(qrCode)
      .then()
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  const handleClickRefresh = () => {
    setExpired((state) => (state = !expired));
    setFgColor("#0063CF");
    fetchData();
  };

  const handleExpired = () => {
    reset();
  };

  const reset = () => {
    dispatch(setQrCodeState(""));
    dispatch(setIsValidForm(false));
    setQrCode("");
    setFgColor("#e6f0fa");
    setExpired(true);
    setExpirationDate("2022-01-01 00:00:00");

    sessionStorage.removeItem("transactionId");
    sessionStorage.removeItem("expirationDate");
  };

  const checkExpiredDate = (value) => {
    var expirationDate = new Date(value);
    expirationDate.setHours(expirationDate.getHours() - 3);
    var now = new Date();
    return expirationDate.getTime() <= now.getTime();
  };

  const checkStatusPayment = (transactionId, recursive = false) => {
    let status = "";

    InvoiceService.handleGetTransactionPix(invoice.id, transactionId)
      .then((res) => {
        if (res && res.data) {
          const { transaction_status, transaction_pix } = res.data.data[0];
          const { external_id } = transaction_status;
          const { qr_code_emv } = transaction_pix;
          status = external_id;

          if (status === "pending" && !recursive) {
            dispatch(setQrCodeState(qr_code_emv));
            dispatch(setIsValidForm(true));
            setQrCode(qr_code_emv);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        switch (status) {
          case "succeeded":
            reset();
            dispatch(setTransactionId(transactionId));
            dispatch(setSuccess(true));
            break;

          case "canceled":
            reset();
            break;

          default:
            setTimeout(
              () => checkStatusPayment(transactionId, true),
              environment.time_to_check
            );
            break;
        }
      });
  };

  const fetchData = useCallback(() => {
    setLoading(true);
    let transactionId = sessionStorage.getItem("transactionId");
    let expiration_date = sessionStorage.getItem("expirationDate");

    if (transactionId) {
      setExpirationDate(expiration_date);
      checkStatusPayment(transactionId);
    } else {
      const buyerPayload = preparePayload(buyer);

      InvoiceService.handleCreateTransactionPix(invoice.id, buyerPayload)
        .then((res) => {
          if (res && res.data) {
            const { transaction_pix, id } = res.data.data[0];
            transactionId = id;
            expiration_date = transaction_pix.expiration_date;

            if (!checkExpiredDate(expiration_date)) {
              setExpirationDate(expiration_date);
              setQrCode(transaction_pix.qr_code_emv);

              dispatch(setIsValidForm(true));
              dispatch(setQrCodeState(transaction_pix.qr_code_emv));

              sessionStorage.setItem("transactionId", transactionId);
              sessionStorage.setItem("expirationDate", expiration_date);

              setTimeout(
                () => checkStatusPayment(transactionId),
                environment.time_to_check
              );
            } else {
              reset();
            }
          }
        })
        .catch((err) => {
          setBadRequest(true);
          Sentry.captureException(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const handleTryAgain = () => {
    handleClickRefresh();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <S.Container>
      {badRequest && !loading && <PixBadRequestScreen onTryAgain={handleTryAgain} />}
      {loading ? (
        <ProcessingScreen />
      ) : (
        <>
          {!badRequest && (
            <>
              <S.QrCodeArea>
                <QRCode value={qrCode} size={200} fgColor={fgColor} />
                {expired && (
                  <S.Refresh onClick={handleClickRefresh}>
                    <Icon iconName={"refresh"} />
                    <label>Atualizar</label>
                  </S.Refresh>
                )}
              </S.QrCodeArea>
              <Ellapsed expirationDate={expirationDate} onExpired={handleExpired} />
              <S.Info>
                Leia o QrCode ou copie o código abaixo para realizar o pagamento via PIX
                em qualquer aplicativo habilitado.
              </S.Info>
              <S.QrCodeCopyAndPaste>
                <input
                  type="text"
                  value={qrCode}
                  placeholder="000000000000000000000000000000..."
                  readOnly
                />
                {qrCode && <Icon iconName="content_copy" onClick={handleBarCodeCopy} />}
              </S.QrCodeCopyAndPaste>
            </>
          )}
          <S.Actions>
            {qrCode && (
              <S.ContainerButtonsShared>
                <EmailShareButton
                  url={""}
                  subject={title}
                  body={qrCode}
                  className="email"
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>

                <WhatsappShareButton
                  url={qrCode}
                  title={title}
                  separator=":: "
                  className="whatsapp"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>

                <TelegramShareButton
                  url={qrCode}
                  title={title}
                  className="Demo__some-network__share-button"
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
              </S.ContainerButtonsShared>
            )}
          </S.Actions>
        </>
      )}
    </S.Container>
  );
}

export default PaymentPix;
