import * as Masks from "../../../../utils/masks";

const preparePayload = (buyer) => {
  const { phone, email, full_name, ...rest } = { ...buyer };

  const copyBuyer = { ...rest };

  copyBuyer.phone = Masks.phone.unmask(phone);
  copyBuyer.postal_code = copyBuyer.postal_code
    ? Masks.cep.unmask(copyBuyer.postal_code)
    : "";

  const address = {
    street: copyBuyer.street,
    number: copyBuyer.number,
    neighborhood: copyBuyer.neighborhood,
    postalCode: copyBuyer.postal_code,
    state: copyBuyer.state,
    city: copyBuyer.city,
    countryCode: "BR",
  };

  if (copyBuyer.complement) {
    address.line2 = copyBuyer.complement;
  }

  const buyerFormatted = {
    fullName: full_name,
    phone: copyBuyer.phone,
    cpf: Masks.cpf.unmask(copyBuyer.cpf),
    email,
    address,
  };

  return { buyer: buyerFormatted };
};

export default preparePayload;
