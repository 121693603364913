import React from "react";
import * as S from "./ConsultationInfoCard.styles";
import { formatCurrency } from "../../../../utils/formatter";
import Card from "../../../V2/Card/Card";

const ConsultationInfoCard = ({ favored, consultation, amount }) => {
  return (
    <Card backgroundColor={"#ffffff"}>
      <S.ConsultationInfoCardContainer>
        <div className="title-container">
          <img src="images/icons/money-icon.png" alt="money-icon" />
          <h5>Cobrança</h5>
        </div>
        <div className="consultation-info">
          <div>
            <p>Favorecido:</p>
            <p>{favored}</p>
          </div>
          <div>
            <p>Referente a:</p>
            <p>{consultation}</p>
          </div>
        </div>
        <div className="amount-container">
          <p>Valor:</p>
          <p className="amount">{formatCurrency(amount)}</p>
        </div>
      </S.ConsultationInfoCardContainer>
    </Card>
  );
};

export default ConsultationInfoCard;
