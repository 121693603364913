import React from "react";
import * as S from "./CardTips.styles";
import Card from "../../../../components/V2/Card/Card";

const CardTips = () => {
  return (
    <Card backgroundColor={"#A0CFF8"}>
      <S.CardTipsContainer>
        <span className="card-tips-title">DICA</span>

        <span className="card-tips-text">
          Os dados informados devem corresponder ao titular do cartão, mesmo que ele não
          seja o paciente.
        </span>
      </S.CardTipsContainer>
    </Card>
  );
};

export default CardTips;
