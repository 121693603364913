import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    id: "",
    sellerName: "",
    value: null,
    description: "",
    installmentOptions: [
      { installments: 1, interestFree: true, installmentValue: 1, totalValue: 1 },
    ],
  },
  error: false,
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState: initialState,
  reducers: {
    setInvoice: (state, action) => {
      return action.payload;
    },
  },
});

export const { setInvoice } = invoiceSlice.actions;

export default invoiceSlice.reducer;
