import styled from "styled-components";

export const CardFormStepsContainer = styled.div`
  padding: 32px 23px 10px 23px;

  .steps-container {
    align-self: center;
    width: 100%;
    padding: 0 6%;
    margin: 0px 0 30px 0;
  }

  .title-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;

    p {
      font-size: 20px;
      font-weight: bold;
    }
  }

  @media (min-width: 768px) {
    padding: 32px 30px 50px 30px;

    .steps-container {
      padding: 0 15%;
    }

    .title-container {
      margin-bottom: 27px;
    }
  }
`;
