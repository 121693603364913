import React, { useState } from "react";
import "../../App.css";
import { useHistory } from "react-router-dom";
import "./style.css";
import useForm from "../../shared/hooks/useForm";
import validate from "../../shared/validations/forgotPasswordValidationRules";
import LinkSent from "./components/LinkSent";
import ForgotPasswordService from "./service";
import * as Sentry from "@sentry/react";
import { Input } from "../../shared/components/UI";
import * as Snowplow from "../../shared/services/snowplow";
import Container from "../../shared/components/Container";
import BasicLoadingScreen from "../BasicLoadingScreen";
import { ButtonPrimary } from "../../shared/components/UI/ButtonPrimary";
import { useSelector } from "react-redux";

export default function ForgotPassword() {
  Snowplow.formTracking();
  const invoiceId = useSelector((state) => state.invoice?.data?.id);
  const initialValues = {
    email: "",
  };
  const [linkSent, setLinkSent] = useState(false);

  const { values, handleChange, onSubmit, errors } = useForm({
    initialValues,
    handleSubmit,
    validate,
  });
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  async function handleSubmit() {
    setLoading(true);
    try {
      await ForgotPasswordService.requestReset(values.email).then();
      setLinkSent(true);
    } catch (e) {
      Sentry.captureException(e);
      console.error("error trying to request a password reset", e);
    } finally {
      setLoading(false);
    }
  }

  const goBack = () => {
    history.push(`/login?invoiceId=${invoiceId}`);
  };

  if (loading) return <BasicLoadingScreen />;
  if (linkSent) return <LinkSent />;

  return (
    <>
      <Container>
        <span className="titlePassword">Use o e-mail da sua conta iClinic Pay</span>
        <form onSubmit={(event) => onSubmit(event)}>
          <Input
            name="email"
            value={values["email"] || ""}
            onChange={handleChange}
            type="email"
            id="email"
            placeholder="Digite seu e-mail"
            errors={errors}
            header="Seu e-mail"
          />
          <div className="row rowButtons mt-24">
            <div className="col-sm-4 colAlignMiddle">
              <ButtonPrimary
                onClick={() => goBack()}
                text="Voltar"
                icon="arrow_back"
                link
                type="button"
              />
            </div>
            <div className="col-sm-8 justify-right">
              <ButtonPrimary
                className="w-216"
                disabled={values["email"] === ""}
                text="Criar nova senha"
              />
            </div>
          </div>
          <div className="smallButtons">
            <ButtonPrimary disabled={values["email"] === ""} text="Criar nova senha" />
            <ButtonPrimary
              onClick={() => goBack()}
              text="Voltar"
              icon="arrow_back"
              link
              type="button"
            />
          </div>
        </form>
      </Container>
    </>
  );
}
