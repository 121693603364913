import React from "react";
import * as S from "./StepsPayment.styles";
import Icon from "../../../../../V2/Icon/Icon";

const StepsPayment = ({ step }) => {
  const steps = [
    { name: "profile", label: "Cadastro" },
    { name: "card", label: "Pagamento" },
  ];

  const stepsWithLine = steps.reduce((acc, step, index) => {
    if (index === 0) {
      return [step];
    }

    return [...acc, { name: "line" }, step];
  }, []);

  let realStepIndex = 0;

  return (
    <S.StepsPaymentContainer>
      {stepsWithLine.map((currentStep, index) => {
        const isCompleted = currentStep.name !== "line" && step > realStepIndex;

        const iconColor = isCompleted ? "white" : "#242E39";

        const iconName =
          currentStep.name === "profile" && step > 1
            ? "check"
            : currentStep.name;

        if (currentStep.name === "line") {
          return <div key={index} className="line" />;
        }

        realStepIndex++;

        return (
          <div key={index}>
            <div
              className={`${
                isCompleted ? "completed" : "incompleted"
              }-container-icon`}
            >
              <Icon name={iconName} color={iconColor} />
            </div>
            <p className={`${isCompleted ? "completed" : "incompleted"}-label`}>
              {currentStep.label}
            </p>
          </div>
        );
      })}
    </S.StepsPaymentContainer>
  );
};

export default StepsPayment;
