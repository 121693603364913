import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import * as S from "./FixedStepControls.styles";
import { formatCurrency } from "../../../../utils/formatter";
import Icon from "../../../V2/Icon/Icon";
import { copyTextOrShare } from "../../../../utils/functions";

const FixedStepControls = ({ amount, onClick, handleBackStep }) => {
  const { isValid, loading, step, paymentMethod, qrCode } = useSelector(
    (state) => state.form
  );

  const [isCopied, setIsCopied] = useState(false);

  const display = loading ? "none" : "";
  const isPix = paymentMethod === "pix";

  const copyCode = async () => {
    await copyTextOrShare(qrCode)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  return (
    <S.FixedStepControlsContainer className="button-container" style={{ display }}>
      <div className="total-container">
        <p className="total-text">Valor:</p>
        <p className="total-value">{formatCurrency(amount)}</p>
      </div>
      <div>
        {step === 2 && (
          <button
            className="btn-next-step btn-icon btn-back-step active"
            type="submit"
            onClick={handleBackStep}
          >
            <Icon name="arrow-left" color="primary" width={20} height={16} />
            Voltar
          </button>
        )}

        <button
          className={`btn-next-step ${isValid ? "active" : "inactive"}-button ${step === 2 && "btn-final-step"} ${isPix && step === 2 && "btn-icon btn-pix"}`}
          onClick={isPix && step === 2 ? copyCode : onClick}
          type="submit"
        >
          {isPix && step === 2 && <Icon name="paper-copy" color="white" />}
          {isCopied
            ? "Copiado!"
            : step === 1
              ? "Avançar para pagamento"
              : isPix
                ? "Copiar código"
                : "Realizar pagamento"}
        </button>
      </div>
    </S.FixedStepControlsContainer>
  );
};

export default FixedStepControls;
