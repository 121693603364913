import styled from "styled-components";

export const Text = styled.div`
  p {
    font-family: "Roboto", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.44px;
    color: #263238;
  }
`;

export const Bold = styled.span`
  font-weight: bold;
  color: #263238;
`;

export const Value = styled.span`
  font-weight: bold;
  color: #0063cf;
`;

export const RemoveSelectedCard = styled.p`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #0063cf;
  cursor: pointer;
  margin-top: 20px;

  @media (max-width: 575px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const ButtonError = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 150px;
  height: 56px;
  background: #e53935;
  border-radius: 4px;
  border: 0;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #f8f9fa;

  @media (max-width: 575px) {
    margin: 24px 0;
    width: 100%;
  }
`;

export const Cancel = styled.p`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #455a64;
  cursor: pointer;

  @media (max-width: 575px) {
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }
`;
export const ModalTitle = styled.span`
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.444444px;
  color: #455a64;
  font-weight: 400;
`;
