import React from "react";
import * as S from "./Card.styles";

const Card = ({ backgroundColor, padding, children }) => {
  return (
    <S.CardContainer style={{ backgroundColor, padding: padding ?? "0" }}>
      {children}
    </S.CardContainer>
  );
};

export default Card;
