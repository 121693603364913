import axios from "axios";
import LocalStorageService from "./shared/services/local-storage";
import { environment } from "./environments/environment";

export class InvoiceService {
  transformInstallments(param) {
    const result = [];
    param.forEach((item) => {
      result.push([item.installments, item.totalValue]);
    });

    return result;
  }

  async fetchInvoice(id) {
    const endpoint = `${environment.endpoints.base_invoice_api_url}/v2/${id}`;
    return axios.get(endpoint);
  }

  async handlePaymentWithoutSavingCard(linkId, formData) {
    const endpoint = `${environment.endpoints.base_invoice_api_url}/${linkId}/transactions/pay/without-saving-card`;

    return axios.post(endpoint, formData);
  }

  async handlePaymentSavingCard(linkId, formData) {
    const endpoint = `${environment.endpoints.base_invoice_api_url}/${linkId}/transactions/pay/saving-card`;
    let user = LocalStorageService.getObject("user");
    return await axios.post(
      endpoint,
      {
        card_number: formData.card_number,
        card_holder_name: formData.card_holder_name,
        user_id: user.id,
        cpf: user.cpf,
        expiration_month: formData.expiration_month,
        expiration_year: formData.expiration_year,
        security_code: formData.security_code,
        installments: Number(formData.installments || 1),
        value: formData.value,
        token: formData.token,
      },
      {
        headers: {
          Authorization: `Bearer ${LocalStorageService.getObject("accessToken")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  }

  async handlePaymentWithExistingCard(linkId, formData) {
    const endpoint = `${environment.endpoints.base_invoice_api_url}/${linkId}/transactions/pay/with-existing-card`;

    return await axios.post(
      endpoint,
      {
        card_id: formData.card_id,
        user_id: LocalStorageService.getObject("user").id,
        installments: Number(formData.installments || 1),
        value: formData.value,
        token: formData.token,
      },
      {
        headers: {
          Authorization: `Bearer ${LocalStorageService.getObject("accessToken")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  }

  async removeSavedCard(cardId) {
    return await axios.delete(
      `${environment.endpoints.base_invoice_api_url}/user/self/card/${cardId}`,
      {
        headers: {
          Authorization: `Bearer ${LocalStorageService.getObject("accessToken")}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  }

  handleCreateTransactionPix(linkId, buyer) {
    const endpoint = `${environment.endpoints.base_invoice_api_url}/${linkId}/pix`;

    return axios.post(endpoint, buyer, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  handleGetTransactionPix(id, transactionId) {
    const endpoint = `${environment.endpoints.base_invoice_api_url}/${id}/pix/${transactionId}`;
    return axios.post(endpoint, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }
}

export default new InvoiceService();
