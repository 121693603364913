import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useForm from "../../shared/hooks/useForm";
import validate from "../../shared/validations/loginFormValidationRules";
import LoginService from "./service";
import LocalStorageService from "../../shared/services/local-storage";
import * as Sentry from "@sentry/react";
import { Input } from "../../shared/components/UI";
import "../../App.css";
import "./style.css";
import * as Snowplow from "../../shared/services/snowplow";
import Container from "../../shared/components/Container";
import { GoToPayment } from "./style";
import BasicLoadingScreen from "../BasicLoadingScreen";
import { ButtonPrimary } from "../../shared/components/UI/ButtonPrimary";
import { Toast } from "../../shared/components/UI/Toast";
import { useSelector } from "react-redux";

export default function Login() {
  const invoiceId = useSelector((state) => state.invoice?.data?.id);
  Snowplow.formTracking({
    fields: {
      denylist: ["password"],
    },
  });

  const initialValues = {
    email: "",
    password: "",
  };
  const { values, handleChange, onSubmit, errors } = useForm({
    initialValues,
    handleSubmit,
    validate,
  });
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  let history = useHistory();

  async function handleSubmit() {
    setLoading(true);
    try {
      const loginRes = await LoginService.login(values.email, values.password);
      handleLoggedUser(loginRes);
    } catch (e) {
      console.error("error trying to log in", e?.response?.data);
      Sentry.captureException(e);
      e?.response?.data?.errorMessage
        ? setErrorMsg(e?.response?.data?.errorMessage)
        : setErrorMsg("Ocorreu um erro, tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  }

  function handleLoggedUser(res) {
    LocalStorageService.setObject("accessToken", res.data.accessToken);
    LocalStorageService.setObject("refreshToken", res.data.refreshToken);
    LocalStorageService.setObject("user", res.data.user);
    if (!res.data.user.cpf)
      return history.push({
        pathname: "/register",
        search: `?invoiceId=${invoiceId}`,
      });

    history.push({
      pathname: "/payment-logged-in",
      search: `?invoiceId=${invoiceId}`,
    });
  }

  useEffect(() => {
    if (LocalStorageService.checkAuthorize()) {
      LocalStorageService.logout();
    }
  }, []);

  const goToForgetPassword = () => {
    history.push("/forgot-password");
  };

  const goToPayment = () => {
    history.push("/payment");
  };

  if (loading) return <BasicLoadingScreen />;

  return (
    <>
      <Toast error msg={errorMsg}></Toast>
      <Container>
        <form onSubmit={(event) => onSubmit(event)}>
          <Input
            name="email"
            id="email"
            value={values["email"] || ""}
            onChange={handleChange}
            type="email"
            placeholder="E-mail"
            errors={errors}
            header="Digite seu e-mail"
          />
          <Input
            name="password"
            id="password"
            value={values["password"] || ""}
            onChange={handleChange}
            type="password"
            placeholder="Digite sua senha"
            showpasswordvisibility="true"
            errors={errors}
            header="Sua senha"
          />
          <div className="row rowButtons mt-24">
            <div className="col-sm-7 colAlignMiddle">
              <ButtonPrimary
                onClick={() => goToForgetPassword()}
                text="Esqueci minha senha"
                link
                type="button"
              />
            </div>
            <div className="col-sm-5">
              <ButtonPrimary
                disabled={values["email"] === "" || values["password"] === ""}
                text="Entrar"
                full
              />
            </div>
          </div>
          <div className="smallButtons m-0-auto">
            <ButtonPrimary
              disabled={values["email"] === "" || values["password"] === ""}
              text="Entrar"
            />
            <ButtonPrimary
              onClick={() => goToForgetPassword()}
              text="Esqueci minha senha"
              link
              type="button"
            />
          </div>
        </form>
        <GoToPayment onClick={() => goToPayment()}>
          Voltar para a tela de pagamento
        </GoToPayment>
      </Container>
    </>
  );
}
