import styled from "styled-components";

export const StepsPaymentContainer = styled.div`
  display: flex;
  gap: 15px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    > div {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
    }

    p {
      font-weight: 600;
      font-size: 12px;
    }
  }

  .completed-container-icon {
    background-color: #0063cf;
  }

  .incompleted-container-icon {
    background-color: #d3d3d3;
  }

  .completed-label {
    color: #0063cf;
  }

  .incompleted-label {
    color: #465668;
  }

  .line {
    position: relative;
    width: 100%;
    border: 1px solid #cfd8dc;
    height: 1px;
    margin-top: 17.5px;
  }
`;
