import * as Yup from "yup";

const schema = Yup.object().shape({
  card_holder_name: Yup.string().required("Nome do titular é obrigatório"),
  card_number: Yup.string().required("Número do cartão é obrigatório"),
  expiration_date: Yup.string().required("Data de validade é obrigatória"),
  security_code: Yup.string().required("CVV é obrigatório"),
  installments: Yup.string().required("Número de parcelas é obrigatório"),
});

export default schema;
