import styled from "styled-components";

export const CardTipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #263238;
  height: 100%;
  padding: 32px 23px 20px 23px;

  .card-tips-title {
    font-size: 16px;
    font-weight: bold;
  }

  .card-tips-text {
    font-size: 15.5px;
    font-weight: 500;
  }

  @media (min-width: 768px) {
    padding: 32px 30px 10px 30px;
  }
`;
