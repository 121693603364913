const cep = {
  mask(value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{3})\d+?$/, "$1");
  },
  unmask(value) {
    return value.replace(/-/, "");
  },
};

export default cep;
