import styled from "styled-components";

export const StepOneContainer = styled.div`
  form {
    .address-container {
      margin-top: 3px;
    }

    .loading {
      opacity: 0.5;
      pointer-events: none;
    }

    .toggle-container {
      margin: 5px 0 27px 1px;

      label {
        margin: 0px 0 0 10px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
      }

      input {
        height: 25px;
        width: 50px;
      }
    }

    .input-container {
      height: 80px;
    }

    .street-address-loading,
    .state-address-loading,
    .city-address-loading {
      opacity: 0.5;
    }
  }

  @media (min-width: 768px) {
    form {
      min-height: 670px;

      .input-container {
        width: 47%;
      }

      .address-container {
        margin-top: 5px;
      }

      .inputs-side-by-side {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;
