import React from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import { ButtonPrimary } from "../../shared/components/UI/ButtonPrimary";
import { useSelector } from "react-redux";

export default function RegisterSuccess() {
  let history = useHistory();
  const invoiceId = useSelector((state) => state.invoice?.data?.id);
  const goToPayment = () => {
    history.push({
      pathname: "/payment-logged-in",
      search: `?invoiceId=${invoiceId}`,
    });
  };

  return (
    <div className="container-success">
      <div className="box">
        <div className="text-center">
          <img alt="" id="success" />
          <br />
          <span className="titleSuccess">Cadastro realizado com sucesso!</span>
          <br />
          <ButtonPrimary
            className="m-0-auto mt-24"
            onClick={goToPayment}
            text="Seguir para pagamento"
          />
        </div>
      </div>
    </div>
  );
}
