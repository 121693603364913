import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.css';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import store from "./redux/store";

Sentry.init({
  dsn:
    "https://be318a6fa2634db5a492912ffbc2d721@o253472.ingest.sentry.io/5331119",
  environment:
    document.location.host === "pagar.com.medicinae.solutions"
      ? "production"
      : document.location.host.indexOf(".pagar.com.medicinae.solutions") !== -1
      ? document.location.host.replace(".pagar.com.medicinae.solutions", "")
      : "local",
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
