import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  dataFields,
  defaultValues,
  preparePayload,
  requiredFields,
  schema,
} from "./utils";
import * as S from "./CreditCardMethod.styles";
import Icon from "../../../../../../../V2/Icon/Icon";
import { Select } from "../../../../../../../../shared/components/UI";
import { environment } from "../../../../../../../../environments/environment";
import {
  setIsValidForm,
  setLoading,
  setSuccess,
  setTransactionId,
} from "../../../../../../../../redux/slices/formSlice";
import InvoiceService from "../../../../../../../../service";
import InputService from "../../../../../../../../functions/InputService/InputService";
import * as Masks from "../../../../../../../../utils/masks";
import renderInstallments from "../../../../../../../../utils/renderInstallments";
import trimData from "../../../StepOne/utils/trimData";

const CreditCardMethod = ({ formRef }) => {
  const [token, setToken] = useState("");
  const transactionData = useSelector((state) => state.personalData);
  const [errorTransaction, setErrorTransaction] = useState(false);
  const invoice = useSelector((state) => state.invoice?.data);

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onSubmit",
  });

  const inputService = new InputService(control, errors);

  const captchaRef = useRef(null);

  const onSubmit = useCallback(
    async (data) => {
      dispatch(setLoading(true));

      try {
        const trimmedData = trimData(data);
        const formData = preparePayload(trimmedData, transactionData, token, invoice);

        const result = await InvoiceService.handlePaymentWithoutSavingCard(
          invoice.id,
          formData
        );

        if (!result.data?.id) {
          throw new Error("Erro ao processar pagamento");
        }

        dispatch(setSuccess(true));
        dispatch(setTransactionId(result.data.id));

        return result;
      } catch (error) {
        setErrorTransaction(true);

        const errorFields = [
          "cardNumber",
          "expirationDate",
          "securityCode",
          "cardHolderName",
          "cpf",
        ];

        errorFields.forEach((field) => {
          const fieldData = dataFields[field];

          setError(fieldData.name, {
            type: "manual",
            message: `Verifique o campo ${fieldData.header}`,
          });

          const clearFieldsAfterFourSeconds = setTimeout(() => {
            clearErrors(fieldData.name);
            clearTimeout(clearFieldsAfterFourSeconds);
          }, 4000);
        });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, token, transactionData, clearErrors, setError, invoice]
  );

  useEffect(() => {
    if (formRef.current) {
      formRef.current.onsubmit = handleSubmit(onSubmit);
    }
  }, [formRef, handleSubmit, onSubmit]);

  const values = watch(requiredFields);

  useEffect(() => {
    const allFieldsFilled = values.every((value) => value !== "");

    if (token) {
      dispatch(setIsValidForm(allFieldsFilled));
    }
  }, [values, token, dispatch]);

  const handleExpirationDate = (e) => {
    const value = Masks.date.mask(e.target.value);
    setValue("expiration_date", value);
  };

  const handleCardNumber = (e) => {
    const value = Masks.cardNumber.mask(e.target.value);
    setValue("card_number", value);
  };

  return (
    <S.CreditCardMethodContainer>
      {errorTransaction ? (
        <div className="info-container info-container-error">
          <Icon name="padlock" color="error" width={20} height={25} />
          <p>Falha ao processar pagamento. Reveja os dados do cartão e tente novamente</p>
        </div>
      ) : (
        <div className="info-container">
          <Icon name="padlock" color="secondary" width={20} height={25} />
          <p>Para sua segurança, não armazenamos dados do seu cartão</p>
        </div>
      )}

      <div className={`title-container ${errorTransaction && "title-container-error"}`}>
        <img src="images/icons/card-icon.png" alt="local-icon" />
        <p>Dados do Cartão</p>
      </div>

      <form ref={formRef}>
        <div>
          {inputService.renderInput(dataFields.cardHolderName)}
          {inputService.renderInput({
            ...dataFields.cardNumber,
            internalOnChange: handleCardNumber,
          })}
          <div className="inputs-side-by-side">
            {inputService.renderInput({
              ...dataFields.expirationDate,
              internalOnChange: handleExpirationDate,
            })}
            {inputService.renderInput(dataFields.securityCode)}
          </div>
          <Controller
            name="installments"
            control={control}
            render={({ field: { onChange, ...field } }) => (
              <Select
                {...field}
                header="Número de parcelas"
                name="installments"
                id="installments"
                value={getValues("installments")}
                ref={captchaRef}
                onChange={(e) => {
                  onChange(e);
                  clearErrors("installments");
                }}
                options={renderInstallments(invoice.installmentOptions)}
              />
            )}
          />
        </div>

        <div className="captcha-container">
          <ReCAPTCHA
            sitekey={environment.captcha_site_key}
            ref={captchaRef}
            onChange={setToken}
          />
        </div>
      </form>
    </S.CreditCardMethodContainer>
  );
};

export default CreditCardMethod;
