import React, { useRef } from "react";
import * as S from "./CardFormSteps.styles";
import Card from "../../../V2/Card/Card";
import { StepsPayment } from "./components";
import { StepOne, StepTwo } from "./Steps";
import { useDispatch, useSelector } from "react-redux";
import FixedStepControls from "../FixedStepControls/FixedStepControls";
import { setStep } from "../../../../redux/slices/formSlice";

const CardFormSteps = ({ amount }) => {
  const currentStep = useSelector((state) => state.form.step);
  const dispatch = useDispatch();

  const formRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (currentStep === 1) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }

    if (currentStep === 2) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  const handleBackStep = () => {
    dispatch(setStep(1));
  };

  return (
    <>
      <Card backgroundColor="#ffffff" padding="0px">
        <S.CardFormStepsContainer>
          <div className="steps-container">
            <StepsPayment step={currentStep} />
          </div>

          {currentStep === 1 ? (
            <StepOne formRef={formRef} />
          ) : (
            <StepTwo formRef={formRef} />
          )}
        </S.CardFormStepsContainer>
      </Card>
      <FixedStepControls
        amount={amount}
        onClick={handleSubmit}
        handleBackStep={handleBackStep}
      />
    </>
  );
};

export default CardFormSteps;
