import styled from "styled-components";

export const CreditCardMethodContainer = styled.div`
  .info-container {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px 12px 22px;
    color: #455a64;
    font-size: 15px;
    background-color: #d9ebfc;
    margin-top: 30px;
    font-weight: 500;
  }

  .info-container-error {
    background-color: #f9e0e0;
    color: #da3333;
    margin-bottom: 30px;
  }

  .title-container {
    margin-top: 30px;
  }

  .title-container-error {
    display: none;
  }
  .input-container {
    position: relative;
  }

  .icon-container {
    position: absolute;
    right: 10px;
    top: 48%;
    transform: translateY(-50%);
  }

  form {
    gap: 10px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .inputs-side-by-side {
        display: flex;
        gap: 20px;
      }
    }
  }

  .captcha-container {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 768px) {
    min-height: 625px;

    .info-container {
      padding: 12px 16px 12px 16px;
    }

    .inputs-side-by-side {
      display: flex;
      justify-content: space-between;

      > div {
        width: 100%;
      }
    }
  }
`;
