import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dataFields, schema } from "./utils";
import * as S from "./StepOne.styles";
import RegisterService from "../../../../../Register/service";
import { setPersonalData } from "../../../../../../redux/slices/personalDataSlice";
import {
  setIsValidForm,
  setFormPendingLoading,
  setStep,
} from "../../../../../../redux/slices/formSlice";
import requiredFields from "./utils/requiredFields";
import trimData from "./utils/trimData";
import InputService from "../../../../../../functions/InputService/InputService";
import * as Masks from "../../../../../../utils/masks";

const StepOne = ({ formRef }) => {
  const dispatch = useDispatch();
  const { loadingGetForm } = useSelector((state) => state.form);
  const { address, ...rest } = useSelector((state) => state.personalData);

  const defaultValues = { ...address, ...rest };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onSubmit",
  });

  const inputService = new InputService(control, errors);

  const values = watch(requiredFields);

  useEffect(() => {
    const allFieldsFilled = values.every((value) => value !== "");

    dispatch(setIsValidForm(allFieldsFilled));
  }, [values, dispatch]);

  const onSubmit = useCallback(
    async (data) => {
      const trimmedData = trimData(data);
      dispatch(setPersonalData(trimmedData));
      dispatch(setStep(2));
      dispatch(setIsValidForm(false));
    },
    [dispatch]
  );

  useEffect(() => {
    if (formRef.current) {
      formRef.current.onsubmit = handleSubmit(onSubmit);
    }
  }, [formRef, handleSubmit, onSubmit]);


  const handleCepChange = async (event) => {
    const cep = Masks.cep.mask(event.target.value);
    setValue("postal_code", cep);

    if (cep.length === 9) {
      dispatch(setFormPendingLoading(true));

      try {
        const cepWithoutMask = Masks.cep.unmask(cep);

        const { data } = await RegisterService.getAddressFromCep(cepWithoutMask);
        const { bairro, logradouro, uf, localidade } = data;

        setValue("street", logradouro);
        setValue("state", uf);
        setValue("city", localidade);
        setValue("neighborhood", bairro);

        clearErrors(["street", "state", "city", "postal_code", "neighborhood"]);
      } catch (error) {
        console.error("Erro ao buscar CEP:", error);
      } finally {
        dispatch(setFormPendingLoading(false));
      }
    }
  };

  const handlePhoneChange = (event) => {
    const phone = Masks.phone.mask(event.target.value);
    setValue("phone", phone);
  };

  const handleCpf = (e) => {
    const value = Masks.cpf.mask(e.target.value);
    setValue("cpf", value);
  };

  return (
    <S.StepOneContainer>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="title-container personal-data">
            <img src="images/icons/profile-icon.png" alt="profile-icon" />
            <p>Dados Pessoais</p>
          </div>
          <div className="inputs-side-by-side">
            {inputService.renderInput(dataFields.full_name)}
            {inputService.renderInput({ ...dataFields.cpf, internalOnChange: handleCpf })}
          </div>
          <div className="inputs-side-by-side">
            {inputService.renderInput(dataFields.email)}
            {inputService.renderInput({
              ...dataFields.phone,
              internalOnChange: handlePhoneChange,
            })}
          </div>
        </div>
        <div>
          <div className="title-container address-container">
            <img src="images/icons/local-icon.png" alt="local-icon" />
            <p>Endereço</p>
          </div>
          <div className={`inputs-side-by-side ${loadingGetForm && "loading"}`}>
            {inputService.renderInput({
              internalOnChange: handleCepChange,
              ...dataFields.postalCode,
            })}
            {inputService.renderInput(dataFields.street)}
          </div>
          <div className="inputs-side-by-side">
            {inputService.renderInput(dataFields.number)}
            {inputService.renderInput(dataFields.complement)}
          </div>
          <div className={`inputs-side-by-side ${loadingGetForm && "loading"}`}>
            {inputService.renderInput(dataFields.neighborhood)}
            {inputService.renderInput(dataFields.city)}
          </div>
          <div className={`inputs-side-by-side ${loadingGetForm && "loading"}`}>
            {inputService.renderInput(dataFields.state)}
          </div>
        </div>
      </form>
    </S.StepOneContainer>
  );
};

export default StepOne;
