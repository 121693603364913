import * as Yup from "yup";

const schema = Yup.object().shape({
  full_name: Yup.string().max(64, "O campo Nome Completo deve ter no máximo 64 caracteres.").required("O campo Nome Completo é obrigatório."),
  cpf: Yup.string()
    .required("CPF é obrigatório")
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "CPF inválido."),
  email: Yup.string().email("E-mail inválido!").required("O campo E-mail é obrigatório."),
  phone: Yup.string()
    .matches(/^\(\d{2}\)\s\d{4,5}-\d{4}$/, "Número de telefone inválido.")
    .required("O campo Telefone é obrigatório."),
  postal_code: Yup.string()
    .matches(/^\d{5}-?\d{3}$/, "CEP inválido.")
    .required("O campo CEP é obrigatório."),
  street: Yup.string().required("O campo Logradouro é obrigatório."),
  number: Yup.string().required("O campo Número é obrigatório."),
  complement: Yup.string(),
  neighborhood: Yup.string().required("O campo Bairro é obrigatório."),
  state: Yup.string().required("O campo Estado é obrigatório.").max(2),
  city: Yup.string().required("O campo Cidade é obrigatório."),
  saveData: Yup.boolean(),
});

export default schema;
