import React, { memo, useEffect, useState, forwardRef } from "react";
import "./styles.css";

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.value === nextProps.value &&
    prevProps.errors[prevProps.name] === nextProps.errors[nextProps.name]
  );
};

const Input = memo(
  forwardRef(({ form, ...props }, ref) => {
    const [showMsg, setShowMsg] = useState(false);
    const [focusedElement, setFocusedElement] = useState("");
    const [inputType, setInputType] = useState(
      props.showpasswordvisibility ? "password" : "text"
    );
    const [icon, setIcon] = useState("visibility_off");

    const toggleVisiblityPassword = () => {
      setInputType(inputType === "text" ? "password" : "text");
      setIcon(inputType === "text" ? "visibility_off" : "visibility");
    };

    useEffect(() => {
      const existError =
        props.errors &&
        Object.keys(props.errors).length > 0 &&
        props.errors[props.name];
      setShowMsg(existError);
    }, [props.errors, props.name]);

    const focusField = () => {
      setFocusedElement(document.activeElement.id);
    };

    const blurField = () => {
      setFocusedElement("");
    };

    return (
      <span className="field">
        <div
          className={`box-field box-input ${showMsg ? "input-error" : ""} ${
            focusedElement === props.id ? "input-valid" : ""
          }`}
        >
          <span
            className={`header ${showMsg ? "header-invalid" : ""} ${
              (props.value !== "" || focusedElement === props.id) && !showMsg
                ? "header-valid"
                : ""
            }`}
          >
            {props.header}
          </span>
          {!props.showpasswordvisibility && (
            <input
              {...props}
              ref={ref}
              className="form-control form-transaction"
              onFocus={focusField}
              onBlur={blurField}
              type={inputType}
            />
          )}

          {props.showpasswordvisibility && (
            <div className="iconPassword">
              <div className="col-sm-10">
                <input
                  {...props}
                  ref={ref}
                  className="form-control form-transaction"
                  onFocus={focusField}
                  onBlur={blurField}
                  type={inputType}
                />
              </div>
              <div className="col-sm-2">
                <span
                  className={`material-icons show-password ${
                    inputType === "text" ? "show" : "hide"
                  }`}
                  onClick={toggleVisiblityPassword}
                >
                  {icon}
                </span>
              </div>
            </div>
          )}
        </div>
        {showMsg && <div className="msg-error">{props.errors[props.name]}</div>}
      </span>
    );
  }),
  areEqual
);

export default Input;
