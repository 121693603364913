/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../App.css";
import "./style.css";
import useForm from "../../shared/hooks/useForm";
import validate from "../../shared/validations/resetPasswordValidationRules";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import ResetPasswordService from "./service";
import * as Sentry from "@sentry/react";
import { Input } from "../../shared/components/UI";
import * as Snowplow from "../../shared/services/snowplow";
import Container from "../../shared/components/Container";
import { ButtonPrimary } from "../../shared/components/UI/ButtonPrimary";
import { Toast } from "../../shared/components/UI/Toast";
import BasicLoadingScreen from "../BasicLoadingScreen";
import { useSelector } from "react-redux";

export default function ResetPassword() {
  Snowplow.formTracking({
    fields: {
      denylist: ["password", "password_confirmation"],
    },
  });
  const initialValues = {
    email: "",
    password: "",
    password_confirmation: "",
  };
  const invoiceId = useSelector((state) => state.invoice?.data?.id);

  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { values, handleChange, onSubmit, errors } = useForm({
    handleSubmit,
    validate,
    initialValues,
  });
  const [errorMsg, setErrorMsg] = useState(null);
  const [success, setSuccess] = useState(false);
  const [disable, setDisable] = useState(true);

  async function handleSubmit() {
    setLoading(true);
    const { email, password } = values;
    const { id, token } = queryString.parse(location.search);
    const formData = {
      email,
      password,
      token,
    };
    try {
      await ResetPasswordService.createNewPassword(id, formData);
      setSuccess(true);
      setErrorMsg(null);
      setTimeout(
        () =>
          history.push({
            pathname: "/login",
            search: `?invoiceId=${invoiceId}`,
          }),
        3000
      );
    } catch (e) {
      e?.response?.data?.errorMessage
        ? setErrorMsg(e.response.data.errorMessage)
        : setErrorMsg("Ocorreu um erro, tente novamente mais tarde.");
      Sentry.captureException(e);
    } finally {
      setLoading(false);
    }
  }

  const dsb = () => {
    let res = false;
    // eslint-disable-next-line
    for (let [key, value] of Object.entries(values)) {
      if (!value) {
        res = true;
        break;
      }
    }
    setDisable(res);
  };

  useEffect(() => {
    dsb();
  }, [dsb, values]);

  if (loading) return <BasicLoadingScreen />;

  return (
    <>
      {errorMsg !== null && <Toast error msg={errorMsg}></Toast>}
      {success && (
        <Toast
          success
          msg={"Senha alterada com sucesso! Redirecionando para o login..."}
        ></Toast>
      )}
      <Container>
        <span className="titlePassword">Crie uma nova senha</span>
        <form onSubmit={(event) => onSubmit(event)} style={{ width: "400px" }}>
          <Input
            name="email"
            id="email"
            value={values["email"] || ""}
            onChange={handleChange}
            type="email"
            placeholder="Digite seu e-mail"
            header="Seu e-mail"
            errors={errors}
          />
          <Input
            name="password"
            id="password"
            value={values["password"] || ""}
            onChange={handleChange}
            placeholder="******"
            header="Sua senha"
            errors={errors}
            showpasswordvisibility="true"
          />
          <Input
            name="password_confirmation"
            id="password_confirmation"
            value={values["password_confirmation"] || ""}
            onChange={handleChange}
            placeholder="******"
            header="Confirme a senha"
            errors={errors}
            showpasswordvisibility="true"
          />
          <ButtonPrimary
            text="Criar nova senha"
            className="mt-24"
            full
            disabled={disable}
          />
        </form>
      </Container>
    </>
  );
}
