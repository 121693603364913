import styled from "styled-components";

export const StepTwoContainer = styled.div`
  color: #455a64;
  margin-bottom: 24px;

  .active {
    background-color: #0063cf;
    color: white;

    svg {
      fill: white;
    }
  }

  .payment-methods {
    display: flex;
    flex-direction: column;
    gap: 24px;

    > div {
      border: 2px solid #0063cf;
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 16px 15px;
      border-radius: 8px;

      p {
        font-size: 16px;
        padding-top: 1px;
        font-weight: 700;
      }
    }
  }

  @media (min-width: 768px) {
    .payment-methods {
      flex-direction: row;

      > div {
        width: 50%;
      }
    }
  }
`;
