import * as Masks from "../../../../../../../../../utils/masks";

const preparePayload = (data, transactionData, token, invoice) => {
  data.card_number = data.card_number.replace(/\s/g, "");

  const expirationDate = data.expiration_date.split("/");

  data.expiration_month = expirationDate[0];
  data.expiration_year = "20" + expirationDate[1];

  delete data.expiration_date;

  data.installments = parseInt(data.installments, 10);

  const buyer = {
    phone: Masks.phone.unmask(transactionData.phone),
    email: transactionData.email,
    fullName: transactionData.full_name,
    cpf: Masks.cpf.unmask(transactionData.cpf),
  };

  buyer.address = {
    countryCode: "BR",
    state: transactionData.state,
    city: transactionData.city,
    postalCode: Masks.cep.unmask(transactionData.postal_code),
    street: transactionData.street,
    number: transactionData.number,
    neighborhood: transactionData.neighborhood,
    line2: transactionData.complement,
  };

  if (!transactionData.complement) {
    delete buyer.address.line2;
  }

  const formData = {
    ...data,
    token: token,
    value: invoice.value,
    cpf: Masks.cpf.unmask(transactionData.cpf),
    buyer,
  };

  return formData;
};

export default preparePayload;
