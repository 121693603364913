import styled from "styled-components";

export const ConsultationInfoCardContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
  color: #455a64;
  padding: 32px 23px 32px 23px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    font-size: 15px;
    font-weight: 500;
  }

  .title-container {
    gap: 15px;
    justify-content: start;

    h5 {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .consultation-info {
    flex-direction: column;
    gap: 20px;

    > div {
      width: 100%;
      gap: 20px;

      p:last-child {
        font-size: 15px;
        font-weight: 700;
      }
    }
  }

  .amount-container {
    display: none;
  }

  @media (min-width: 768px) {
    padding: 32px 30px 10px 30px;

    p {
      font-size: 16px;
      font-weight: 500;
    }

    .consultation-info {
      padding-bottom: 15px;
      border-bottom: 2px solid #cfd8dc;

      > div {
        display: flex;
        justify-content: space-between;

        p:last-child {
          font-size: 16px;
          text-align: end;
        }
      }
    }

    .amount-container {
      display: flex;
    }

    .amount {
      font-size: 21px;
      font-weight: bold;
      color: #0063cf;
    }
  }
`;
