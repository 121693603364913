import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  full_name: "",
  cpf: "",
  email: "",
  phone: "",
  address: {
    street: "",
    number: "",
    neighborhood: "",
    complement: "",
    postal_code: "",
    state: "",
    city: "",
  },
};

const personalDataSlice = createSlice({
  name: "personalData",
  initialState: initialState,
  reducers: {
    setPersonalData: (state, action) => {
      return action.payload;
    },
  },
});

export const { setPersonalData } = personalDataSlice.actions;
export default personalDataSlice.reducer;
